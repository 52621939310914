<template>
  <div class="project-refund-approve">
    <fd-form class="card" @submit="submitForm">
      <modal-body class="p-3">
        <h2 class="mb-4">Approve Refund</h2>

        <div
          v-if="isLoading"
          class="d-flex justify-content-center align-items-center"
        >
          <spinner></spinner>
        </div>
        <div v-else>
          <fd-input
            v-model="form.refundDeposit"
            class="col-12 mb-2"
            label="Refund Amount"
            name="refundDeposit"
            type="text"
            :validators="[validator.required, validator.price]"
          >
          </fd-input>

          <file-uploader
            v-model="form.refundReceipt"
            class="col-12 px-1 mb-2"
            label="Refund Receipt"
            :maxSize="5"
            :allowed="['pdf', 'jpg', 'png', 'jpeg']"
            accept=".pdf, image/jpeg, image/png"
            @isLoading="
              (isLoading) => {
                this.isFormLoading = isLoading;
              }
            "
            required
          ></file-uploader>
          <fd-textarea
            v-model="form.remark"
            class="col-12 mb-2"
            label="Remark"
            name="approveRefundRemark"
          >
          </fd-textarea>
        </div>
      </modal-body>
      <modal-footer class="row justify-content-end p-2">
        <fd-button class="mr-1" @click="$emit('cancel')">Cancel</fd-button>
        <fd-button
          type="submit"
          loadingEnabled
          :isLoading="isFormLoading"
          :disabled="isFormLoading"
          class="main"
          >Approve</fd-button
        >
      </modal-footer>
    </fd-form>
  </div>
</template>

<script>
import {
  required,
  price
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { projectRefund as refundAPI } from "@/api";
import { ProjectRefundModel as refundModel } from "@/models";

export default {
  components: {
    FileUploader: () => import("@/components/GlobalComponents/FileUploader"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [],
  props: {
    refundId: {
      type: [String, Number],
      required: true
    }
  },
  data: function () {
    return {
      isFormLoading: false,
      isLoading: false,

      form: {
        refundDeposit: "",
        refundReceipt: [],
        remark: ""
      },
      validator: {
        required: required,
        price: price
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.getBuyerDepositAmount();
  },
  methods: {
    async getBuyerDepositAmount() {
      try {
        this.isLoading = true;
        let data = await refundAPI.getRefundDeposit(this.refundId);
        this.form.refundDeposit = this._.cloneDeep(data);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get buyer deposit amount. Please try again later."
        });
        this.$emit("cancel");
      }
    },
    submitForm() {
      this.$emit(
        "submit",
        this.refundId,
        refundModel.putApprovePayload(this.form)
      );
    }
  }
};
</script>

<style lang="scss">
.project-refund-approve {
  @media #{$breakpoint-up-sm} {
    min-width: 500px;
  }
}
</style>
